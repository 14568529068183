<template>
  <v-container class="own-page pb-12">
    <v-card>
      <v-card-title>
        <span>{{ $t("Pages") }}</span>
        <v-spacer></v-spacer>
        <v-btn to="/page/new">{{ $t("CreatePage") }}</v-btn>
      </v-card-title>
      <v-card-text>
        <v-skeleton-loader :loading="is_loading" height="150" type="article">
          <div>
            <v-simple-table v-if="!page_error">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{ $t("Title") }}</th>
                    <th class="text-left">{{ $t("IsPublished") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in pages" :key="item.id">
                    <td>
                      <router-link :to="'/page/edit/' + item.id">{{ item.title }}</router-link>
                    </td>
                    <td>{{ item.is_active }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <error-page :error="page_error"></error-page>
          </div>
        </v-skeleton-loader>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Pages": "Pages",
    "IsPublished": "Is published",
    "PageTitle": "Manage pages",
    "CreatePage": "Create page"
    },
    "sv": {
    "Pages": "Sidor",
    "IsPublished": "Har publicerats",
    "PageTitle": "Hantera sidor",
    "CreatePage": "Skapa sida"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { systemPagesService } from "../../../_services";

export default {
  name: "page_form",
  metaInfo() {
    return {
      title: this.$t("PageTitle")
    };
  },
  data: () => ({
    page_error: null,
    is_loading: false,
    pages: []
  }),
  computed: {
    ...mapState({
      current_user: state => state.account.user
    })
  },
  created: function() {
    if (!this.$store.getters["account/isInRole"]("Admin")) {
      this.$router.push("/");
      return;
    }

    this.loadNews();
  },
  methods: {
    loadNews() {
      var self = this;
      self.is_loading = true;
      self.page_error = null;

      systemPagesService
        .getPages()
        .then(function(response) {
          self.is_loading = false;
          self.pages = response.data;
        })
        .catch(function(error) {
          self.page_error = error;
          self.is_loading = false;
        });
    }
  }
};
</script>

<style></style>

<template>
  <form-dialog
    v-model="is_open"
    :title="title"
    v-on:submit="done"
    :submit-text="$t('Button.Done')"
    max-width="800px"
    content-class="max-400"
    :submit-disabled="!selected_medias || selected_medias.length == 0"
  >
    <template v-slot:content>
      <v-container>
        <v-row>
          <v-col xs="6" md="3" cols="12" v-if="canUpload">
            <v-card
              height="150"
              @click="pickImage"
              class="text-center"
              :loading="uploading"
              :disabled="uploading"
            >
              <v-icon x-large class="mt-12">$vuetify.icons.plus</v-icon>
            </v-card>
            <input
              type="file"
              style="display: none"
              ref="image"
              accept="image/*"
              v-on:change="onFilePicked"
            />
          </v-col>

          <v-col xs="6" md="3" cols="12" v-for="item in images" :key="item.url">
            <v-card
              height="150"
              @click="imageClick(item)"
              :class="{ 'selected-media': isSelected(item) }"
            >
              <v-img :src="item.thumb_150" height="150" class="grey lighten-2">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
    "SelectImages": "Select images",
    "SelectImage": "Select image",
    "ImageUploaded": "Image uploaded!"
    },
    "sv": {
    "SelectImages": "Välj bilder",
    "SelectImage": "Välj bild",
    "ImageUploaded": "Bilden laddades upp!"
    }
    }
</i18n>

<script>
import { globalService } from "../../_services";
import Compressor from "compressorjs";

export default {
  name: "image_gallery_picker_dialog",
  props: {
    max: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    canUpload: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    is_open: false,
    is_loading: false,
    images: [],
    selected_medias: [],
    error: null,
    uploading: false,
  }),
  computed: {
    title: function () {
      if (this.max > 1) {
        return this.$t("SelectImages");
      } else return this.$t("SelectImage");
    },
  },
  methods: {
    open() {
      this.is_open = true;

      if (this.type == "news") {
        this.loadNews();
      }
    },
    loadNews() {
      var self = this;
      self.is_loading = true;

      globalService
        .getNewsMedias()
        .then(function (response) {
          self.images = response.data;
          self.is_loading = false;
        })
        .catch(function (error) {
          self.error = error;
          self.is_loading = false;
        });
    },
    isSelected(item) {
      return (
        this.selected_medias.filter(
          (element) => element.filename == item.filename
        ) != ""
      );
    },
    imageClick(image) {
      var self = this;

      if (self.isSelected(image)) {
        self.selected_medias = [
          ...self.selected_medias.filter(
            (element) => element.filename !== image.filename
          ),
        ];
      } else {
        if (self.selected_medias.length >= self.max) {
          self.selected_medias.pop();
        }

        self.selected_medias.push(image);
      }
    },
    done() {
      //for (var i = 0; i < this.selected_medias.length; i++)
      //this.selected_medias[i] = this.selected_medias[i].url;

      this.$emit("done", this.selected_medias);
      this.is_open = false;
    },
    pickImage() {
      this.$refs.image.click();
    },
    onFilePicked(e) {
      var self = this;

      const file = e.target.files[0];

      if (!file) {
        return;
      }
      self.uploading = true;

      new Compressor(file, {
        quality: 0.8,
        minWidth: 100,
        minHeight: 100,
        maxHeight: 1500,
        maxWidth: 1500,
        success(result) {
          globalService
            .uploadNewsMedias(result)
            .then(function (response) {
              self.images.push(response.data.image);
              self.uploading = false;
              self.$successNoty(self.$t("ImageUploaded"));
            })
            .catch(function (error) {
              self.$ajaxErrorNoty(error);
              self.uploading = false;
            });
        },
        error(err) {
          self.$errorNoty(err.message);
          self.uploading = false;
        },
      });
    },
  },
};
</script>

<style scoped>
.selected-media {
  border: solid 2px green;
  opacity: 0.5;
}
.selected-media .v-image {
  height: 146px !important;
}
</style>
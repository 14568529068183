<template>
  <v-container class="own-page pb-12">
    <v-card>
      <v-card-title>
        <span v-if="!news_id">{{ $t("CreatePage") }}</span>
        <span v-if="news_id">{{ $t("UpdatePage") }}</span>
      </v-card-title>
      <v-card-text>
        <v-skeleton-loader :loading="is_loading" height="150" type="article">
          <div>
            <router-link to="/news">{{ $t("BackNews") }}</router-link>
            <v-form
              v-if="!page_error"
              ref="form"
              v-model="form"
              lazy-validation
            >
              <v-row class="mt-4">
                <v-col cols="12" md="12" class="mx-auto">
                  <v-text-field
                    :rules="[(v) => !!v || $t('TitleMissing')]"
                    :loading="is_saving"
                    required
                    autofocus
                    outlined
                    v-model="news.title"
                    :label="$t('Title')"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <div class="text-right">
                    <v-btn
                      style="border-color: grey"
                      small
                      outlined
                      @click="openGallery"
                      class="text-none"
                    >
                      <v-icon left small>$vuetify.icons.fasImages</v-icon>
                      {{ $t("PhotoAlbum") }}
                    </v-btn>
                  </div>
                  <tinymce-editor
                    :init="editor"
                    :disabled="is_saving"
                    :api-key="tinymceApiKey"
                    v-model="news.content"
                  ></tinymce-editor>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-textarea
                    :rules="[(v) => !!v || $t('SummeryMissing')]"
                    :loading="is_saving"
                    required
                    outlined
                    v-model="news.summery"
                    :label="$t('Summery')"
                  ></v-textarea>
                  <v-card outlined @click="openCover">
                    <v-card-text class="text-center">
                      <div v-if="!news.cover">{{ $t("NoCover") }}</div>

                      <v-img
                        class="mx-auto"
                        width="100"
                        height="100"
                        v-if="news.cover"
                        :src="getCover(news.cover)"
                      ></v-img>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    :rules="[(v) => !!v || $t('UrlNameMissing')]"
                    :loading="is_saving"
                    required
                    outlined
                    v-model="news.url_name"
                    :label="$t('UrlName')"
                  ></v-text-field>

                  <v-select
                    :items="categories"
                    item-text="name"
                    item-value="name"
                    v-model="news.category"
                    :label="$t('Category')"
                    outlined
                    append-outer-icon="$vuetify.icons.fasPlus"
                    @click:append-outer="category_dialog = true"
                    :rules="[(v) => !!v || $t('CategoryMissing')]"
                  ></v-select>

                  <v-text-field
                    :rules="[(v) => !!v || $t('PublishDateMissing')]"
                    :loading="is_saving"
                    required
                    outlined
                    v-model="news.publish_date"
                    :label="$t('PublishDate')"
                  ></v-text-field>
                  <v-checkbox
                    class="mt-0"
                    v-model="news.is_published"
                    :label="$t('Active')"
                    :loading="is_saving"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row class="pb-10">
                <v-col cols="12" xs="12" class="text-center">
                  <v-btn
                    color="primary"
                    :loading="is_saving"
                    @click="save"
                    class="text-none"
                    >{{ $t("Button.Save") }}</v-btn
                  >
                </v-col>
                <error-box :error="error" class="mx-auto"></error-box>
              </v-row>
            </v-form>
            <error-page :error="page_error" v-if="page_error"></error-page>
          </div>
        </v-skeleton-loader>

        <form-dialog
          v-model="category_dialog"
          :title="$t('Category')"
          v-on:submit="saveCategory"
          :is-saving="category_is_saving"
        >
          <template v-slot:content>
            <v-form ref="category_form" v-model="category_form" lazy-validation>
              <v-text-field
                :rules="[(v) => !!v || $t('TitleMissing')]"
                required
                outlined
                v-model="category.name"
                :label="$t('Title')"
              ></v-text-field>
            </v-form>
          </template>
        </form-dialog>
      </v-card-text>
    </v-card>

    <media-picker
      v-on:done="selectedMedia"
      ref="mediaPicker"
      type="news"
      can-upload
      :max="5"
    ></media-picker>

    <media-picker
      v-on:done="selectedCover"
      ref="mediaCoverPicker"
      type="news"
      can-upload
      :max="1"
    ></media-picker>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Title": "Title",
    "TitleMissing": "Title is missing!",
    "Summery": "Summery",
    "SummeryMissing": "Summery is missing!",
    "Page": "Page",
    "PageMissing": "Page is missing!",
    "Active": "Published",
    "UrlName": "Address",
    "UrlNameMissing": "Address is missing!",
    "CreatePage": "Create news",
    "UpdatePage": "Update news",
    "PublishDateMissing": "Publishdate is missing!",
    "PublishDate": "Publishdate",
    "Category": "Category",
    "CategoryMissing": "Category is missing!",
    "SuccessSaveCategory": "Category saved!",
    "SuccessSaveNews": "News saved!",
    "BackNews": "- News",
    "NoCover": "No selected cover",
    "PhotoAlbum": "Images"
    },
    "sv": {
    "Title": "Titel",
    "TitleMissing": "Titel saknas!",
    "Summery": "Summering",
    "SummeryMissing": "Summering saknas!",
    "Page": "Sida",
    "PageMissing": "Välj sida",
    "Active": "Publicerad",
    "UrlName": "Adress",
    "UrlNameMissing": "Adress saknas!",
    "CreatePage": "Skapa nyhet",
    "UpdatePage": "Uppdatera nyhet",
    "PublishDateMissing": "Publiceringsdatum saknas",
    "PublishDate": "Publiceringsdatum",
    "Category": "Kategori",
    "CategoryMissing": "Kategori saknas!",
    "SuccessSaveCategory": "Kategorin sparades!",
    "SuccessSaveNews": "Nyheten sparades!",
    "BackNews": "- Nyheter",
    "NoCover": "Ingen vald omslagsbild!",
    "PhotoAlbum": "Bilder"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import Editor from "@tinymce/tinymce-vue";
import { newsService } from "@/_services";
import MediaPicker from "@/components/dialogs/ImagePickerDialog.vue";
import * as moment from "moment";

export default {
  name: "page_form",
  metaInfo() {
    return {
      title: this.page_title,
    };
  },
  data: () => ({
    tinymceApiKey: process.env.VUE_APP_TINYMCE_KEY,
    is_saving: false,
    page_error: null,
    page_title: "",
    is_loading: false,
    error: null,
    form: null,
    news_id: null,

    categories: [],

    news: {
      title: "",
      summery: "",
      is_published: false,
      content: "",
      url_name: "",
      category: "",
      cover: "",
    },
    editor: {
      height: 300,
      menubar: true,
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime imagetools media table paste code",
      ],
      toolbar:
        "undo redo | " +
        " bold italic underline | forecolor backcolor | alignleft aligncenter " +
        " alignright alignjustify | bullist numlist outdent indent |" +
        " image media",
      content_css: [
        "//fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900",
      ],
    },

    category_form: null,
    category_dialog: false,
    category_is_saving: false,
    category: {
      name: "",
      url_name: "",
    },
  }),
  components: {
    "tinymce-editor": Editor,
    "media-picker": MediaPicker,
  },
  computed: {
    ...mapState({
      current_user: (state) => state.account.user,
    }),
  },
  created: function () {
    if (!this.$store.getters["account/isInRole"]("Admin")) {
      this.$router.push("/");
      return;
    }

    this.page_title = this.$t("CreatePage");
    this.news.publish_date = moment().format("YYYY-MM-DD");

    if (this.$route.params.id) {
      this.page_title = this.$t("UpdatePage");
      this.news_id = this.$route.params.id;
    }

    this.start();
  },
  methods: {
    start() {
      var self = this;
      self.is_loading = true;
      self.page_error = null;

      newsService
        .categories()
        .then(function (response) {
          self.categories = response.data;
          if (self.$route.params.id) {
            self.load(self.news_id);
          } else {
            self.is_loading = false;
          }
        })
        .catch(function (error) {
          self.page_error = error;
          self.is_loading = false;
        });
    },
    load(id) {
      var self = this;
      self.is_loading = true;
      self.page_error = null;

      newsService
        .getDetails(id)
        .then(function (response) {
          self.news = response.data;
          self.news.category = self.news.category.name;
          self.news.publish_date = moment(self.news.publish_date).format(
            "YYYY-MM-DD"
          );

          self.is_loading = false;
        })
        .catch(function (error) {
          self.page_error = error;
          self.is_loading = false;
        });
    },
    save() {
      var self = this;
      self.is_saving = false;
      self.error = null;

      if (self.$refs.form.validate()) {
        self.is_saving = true;

        var data = { ...self.news };
        if (self.news.cover) data.cover = self.news.cover.filename;

        var req;

        if (self.news_id) req = newsService.update(self.news_id, data);
        else req = newsService.create(data);

        req
          .then(function (response) {
            self.is_saving = false;

            if (response.data && response.data) {
              self.news_id = response.data;
            }
            self.$successNoty(self.$t("SuccessSaveNews"));
          })
          .catch(function (error) {
            self.error = error;
            self.is_saving = false;
          });
      }
    },
    saveCategory() {
      var self = this;
      self.category_is_saving = false;

      if (self.$refs.category_form.validate()) {
        self.categories.push({
          name: self.category.name,
        });

        self.news.category = self.category.name;
        self.category_dialog = false;
      }
    },
    getCover(img) {
      return img.thumb_150;
    },
    openGallery() {
      this.$refs.mediaPicker.open();
    },
    openCover() {
      this.$refs.mediaCoverPicker.open();
    },
    selectedCover(items) {
      if (items.length > 0) {
        for (var i = 0; i < items.length; i++) {
          var obj = items[i];
          this.news.cover = obj;
        }
      }
    },
    selectedMedia(items) {
      if (items.length > 0) {
        for (var i = 0; i < items.length; i++) {
          var obj = items[i];
          var url = obj.width_1024;
          this.news.content +=
            "<img src='" +
            url +
            "' alt='" +
            obj.filename +
            "' title='" +
            obj.filename +
            "' />";
        }
      }
    },
  },
};
</script>

<style></style>

<template>
  <v-container class="own-page pb-12">
    <v-card>
      <v-card-title>
        <span v-if="!page_id">{{ $t("CreatePage") }}</span>
        <span v-if="page_id">{{ $t("UpdatePage") }}</span>
      </v-card-title>
      <v-card-text>
        <v-skeleton-loader :loading="is_loading" height="150" type="article">
          <div>
            <v-form v-if="!page_error" ref="form" v-model="form" lazy-validation>
              <v-row class="mt-4">
                <v-col cols="12" md="12" class="mx-auto">
                  <v-text-field
                    :rules="[v => !!v || $t('TitleMissing')]"
                    :loading="is_saving"
                    required
                    autofocus
                    outlined
                    v-model="page.title"
                    :label="$t('Title')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" class="mx-auto">
                  <tinymce-editor
                    :init="editor"
                    :disabled="is_saving"
                    :api-key="tinymceApiKey"
                    v-model="page.content"
                  ></tinymce-editor>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-textarea
                    :rules="[v => !!v || $t('SummeryMissing')]"
                    :loading="is_saving"
                    required
                    outlined
                    v-model="page.summery"
                    :label="$t('Summery')"
                  ></v-textarea>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    :rules="[v => !!v || $t('UrlNameMissing')]"
                    :loading="is_saving"
                    required
                    outlined
                    v-model="page.url_name"
                    :label="$t('UrlName')"
                  ></v-text-field>
                  <v-checkbox
                    class="mt-0"
                    v-model="page.is_active"
                    :label="$t('Active')"
                    :loading="is_saving"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row class="pb-10">
                <v-col cols="12" xs="12" class="text-center">
                  <v-btn color="primary" :loading="is_saving" @click="save" class="text-none">{{ $t("Button.Save") }}</v-btn>
                </v-col>
                <error-box :error="error" class="mx-auto"></error-box>
              </v-row>
            </v-form>
            <error-page :error="page_error"></error-page>
          </div>
        </v-skeleton-loader>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Title": "Title",
    "TitleMissing": "Title is missing!",
    "Summery": "Summery",
    "SummeryMissing": "Summery is missing!",
    "Page": "Page",
    "PageMissing": "Page is missing!",
    "Active": "Published",
    "UrlName": "Address",
    "UrlNameMissing": "Address is missing!",
    "CreatePage": "Create page",
    "UpdatePage": "Update page"
    },
    "sv": {
    "Title": "Titel",
    "TitleMissing": "Titel saknas!",
    "Summery": "Summering",
    "SummeryMissing": "Summering saknas!",
    "Page": "Sida",
    "PageMissing": "Välj sida",
    "Active": "Publicerad",
    "UrlName": "Adress",
    "UrlNameMissing": "Adress saknas!",
    "CreatePage": "Skapa en sida",
    "UpdatePage": "Uppdatera sida"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import Editor from "@tinymce/tinymce-vue";
import { systemPagesService } from "../../../_services";

export default {
  name: "page_form",
  metaInfo() {
    return {
      title: this.page_title
    };
  },
  data: () => ({
    tinymceApiKey: process.env.VUE_APP_TINYMCE_KEY,
    is_saving: false,
    page_error: null,
    page_title: "",
    is_loading: false,
    error: null,
    form: null,
    page_id: null,

    page: {
      title: "",
      summery: "",
      is_active: false,
      content: "",
      url_name: ""
    },
    editor: {
      height: 300,
      menubar: true,
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime imagetools media table paste code"
      ],
      toolbar:
        "undo redo | " +
        " bold italic underline | forecolor backcolor | alignleft aligncenter " +
        " alignright alignjustify | bullist numlist outdent indent |" +
        " image media",
      content_css: [
        "//fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900"
      ]
    }
  }),
  components: {
    "tinymce-editor": Editor
  },
  computed: {
    ...mapState({
      current_user: state => state.account.user
    })
  },
  created: function() {
    if (!this.$store.getters["account/isInRole"]("Admin")) {
      this.$router.push("/");
      return;
    }

    if (this.$route.query.name) {
      this.page.url_name = this.$route.query.name;
    }
    this.page_title = this.$t("CreatePage");

    if (this.$route.params.id) {
      this.page_title = this.$t("UpdatePage");
      this.page_id = this.$route.params.id;
      this.load(this.page_id);
    }
  },
  methods: {
    load(id) {
      var self = this;
      self.is_loading = true;
      self.page_error = null;

      systemPagesService
        .getDetails(id)
        .then(function(response) {
          self.page = response.data;
          self.is_loading = false;
        })
        .catch(function(error) {
          self.page_error = error;
          self.is_loading = false;
        });
    },
    save() {
      var self = this;
      self.is_saving = false;
      self.error = null;

      if (self.$refs.form.validate()) {
        self.is_saving = true;

        var req;

        if (self.page_id)
          req = systemPagesService.update(self.page_id, self.page);
        else req = systemPagesService.create(self.page);

        req
          .then(function(response) {
            self.is_saving = false;

            if (response.data && response.data.id) {
              self.page_id = response.data;
            }
          })
          .catch(function(error) {
            self.error = error;
            self.is_saving = false;
          });
      }
    }
  }
};
</script>

<style></style>

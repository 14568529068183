<template>
  <v-container class="own-page pb-12">
    <v-card>
      <v-card-title>
        <span>{{ $t("News") }}</span>
        <v-spacer></v-spacer>
        <v-btn to="/news/new">{{ $t("CreateNews") }}</v-btn>
      </v-card-title>
      <v-card-text>
        <v-skeleton-loader :loading="is_loading" height="150" type="article">
          <div>
            <v-simple-table v-if="!page_error">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{ $t("Title") }}</th>
                    <th class="text-left">{{ $t("PublishDate") }}</th>
                    <th class="text-left">{{ $t("IsPublished") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in news" :key="item.id">
                    <td>
                      <router-link :to="'/news/edit/' + item.id">{{ item.title }}</router-link>
                    </td>
                    <td>{{ item.publish_date }}</td>
                    <td>{{ item.is_published }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-col xs="12" cols="12" v-if="unit_pages > 1" class="text-center">
              <v-pagination v-model="page" :length="unit_pages" :total-visible="7"></v-pagination>
            </v-col>

            <error-page :error="page_error" v-if="page_error"></error-page>
          </div>
        </v-skeleton-loader>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Title": "Title",
    "PublishDate": "Publish date",
    "IsPublished": "Is published",
    "PageTitle": "Manage news",
    "BackNews": "- News",
    "CreateNews": "Create news"
    },
    "sv": {
    "Title": "Titel",
    "PublishDate": "Publiceringsdatum",
    "IsPublished": "Har publicerats",
    "PageTitle": "Hantera nyheter",
    "BackNews": "- Nyheter",
    "CreateNews": "Skapa nyhet"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { newsService } from "../../../_services";

export default {
  name: "page_form",
  metaInfo() {
    return {
      title: this.$t("PageTitle")
    };
  },
  data: () => ({
    page_error: null,
    is_loading: false,
    news: [],

    page: 1,
    unit_items: 0,
    unit_pages: 0
  }),
  computed: {
    ...mapState({
      current_user: state => state.account.user
    })
  },
  created: function() {
    if (!this.$store.getters["account/isInRole"]("Admin")) {
      this.$router.push("/");
      return;
    }

    this.setFromUrl();
    this.loadNews();
  },
  methods: {
    loadNews() {
      var self = this;
      self.is_loading = true;
      self.page_error = null;

      newsService
        .newsList(self.page)
        .then(function(response) {
          self.is_loading = false;
          self.news = response.data.data;
          self.unit_pages = response.data.unit_pages;
          self.unit_items = response.data.unit_items;
        })
        .catch(function(error) {
          self.page_error = error;
          self.is_loading = false;
        });
    },
    setFromUrl() {
      this.page = 1;

      if (this.$route.query.page) this.page = parseInt(this.$route.query.page);
    }
  },
  watch: {
    $route() {
      this.setFromUrl();
      this.loadNews();
    },
    page() {
      var self = this;

      if (parseInt(self.$route.query.page) == self.page) return;
      self.$router.push({ query: { page: self.page } });

      self.$vuetify.goTo(0);
    }
  }
};
</script>

<style></style>
